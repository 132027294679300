<template>
  <div>
    <h3 ref="intro">
      Geometry: Lengths & Angles
    </h3>
    </br>
    <p>
      Points and lines are essential elements of any geometry. Lengths and angles
      are quantities that describe the spatial location/orientation of points and lines relative to one another.
    </p>
    <ul class="a mx-3 my-3">
      <li>
        <h5> Length </h5>
        The length describes the distance of one point relative to another point.
        For example, the length of a side of a triangle describes the distance between the two endpoints of that side.
      </li>
      <li>
        <h5> Angle </h5>
        The angle describes the orientation or direction of one line segment relative to another line segment.
        For example, an angle in a triangle describes the direction of one side of the triangle relative to another side.
      </li>
    </ul>
    <h3 ref="meas">
      Measuring Lengths and Angles
    </h3>
    <p />
    <ul class="a mx-3 my-3">
      <li>
        <h5> Measuring Lengths </h5>
        The length of a line segment can be measured using a ruler (or a measuring tape) and a divider (shown below).
      </li>
      <br></br>
      <v-layout justify-center>
        <v-img contain
               src="/assets/ruler.svg"
               alt="A ruler and a divider"
               max-height="250px"
               max-width="250px"
        />
      </v-layout>
      <br></br>
      <li>
        <h5> Measuring Angles </h5>
        The angle between two line segments can be measured using a device called a protractor (shown below).
        <v-layout justify-center>
          <v-img contain
                 src="/assets/protractor.svg"
                 alt="A protractor"
                 max-height="350px"
                 max-width="350px"
          />
        </v-layout>
      </li>
    </ul>
    <h3 ref="graph">
      MagicGraph | Lengths & Angles of a Right-angled Triangle
    </h3>
    </br>
    <p> The goal of this MagicGraph is to help students learn measuring lengths and angles. </p>
    <ul class="a mx-3 my-3">
      <li>
        <h5> Getting Started </h5>
        You're given a ruler and a protractor. Use the ruler to measure the lengths of the three sides of the triangle. Use the protractor to measure
        the angle &ang;AOB of the triangle.
      </li><li>
        <h5> To Explore </h5>
        Drag point B of the triangle to generate different triangle configurations. Explore acute, obtuse, and right angles.
      </li>
    </ul>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'LengthsandAngles',
  created: function () {
    this.$store.commit('navigation/resetState');
    // Store mutations
    let title = 'Distances & Angles';
    this.$store.commit('navigation/changeTitle', 'Lengths & Angles');
    this.$store.commit('navigation/changeMenu', 'Lengths & Angles');
    let newTopics = [
      {title: 'Lengths & Angles', img:'/assets/number-1.svg', action: () => this.goto('intro')},
      {title: 'Measuring Lengths and Angles', img:'/assets/number-2.svg', action: () => this.goto('meas')},
      {title: 'MagicGraph: Generating Conic Sections', img:'/assets/touch.svg', action: () => this.goto('graph')}
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Lengths and Angles',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively about lengths and angles.'}
                ]
        }
   },
}
</script>

<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
