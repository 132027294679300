// Import the edliy_utils
import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeMiddleText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeRightText,
    placeSliderSwitch,
    placeCircle,
    placeLogo,
    placeShuffle,
    placeTest,
    placeErase,
    placeBoldText
} from '../../../../common/edliy_utils-fractions';
const Boxes = {
  box1: function () {
		var brd2 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-4, 16, 16, -4],keepaspectratio: true, axis:true, ticks:false, grid:true, pan:{enabled:false}, zoom:{enabled:false}, showCopyright:false, showNavigation:false});
    ///////////////////////////////BOARD FEATURE OPTIONS//////////////////////////////
    JXG.Options.board.minimizeReflow = 'none';
    JXG.Options.point.showInfoBox=false;
    JXG.Options.point.highlight=false;
    JXG.Options.image.highlight=false;
    JXG.Options.text.highlight=false;
    JXG.Options.text.fixed=true;
    JXG.Options.curve.highlight=false;
    JXG.Options.text.cssDefaultStyle='fontFamily:Oswald;'
    ///////////////////Z-LAYERING /////////////////////
    brd2.options.layer['angle'] =6;
		brd2.options.layer['image'] =2;
		brd2.options.layer['line'] =6;
		brd2.options.layer['point'] =12;
		brd2.options.layer['polygon'] =3;
    /////////////////// MAKE RESPONSIVE //////////////////////////
    makeResponsive(brd2);
		var i =0;
    placeTitle(brd2, 'Lengths & Angles', '')
		//brd2.create('text', [3, 14.8, '<b>Distances & Angles</b>'], {fixed:true, fontSize:function(){return 32*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
		brd2.create('text', [0.5,13, 'Measuring tape to measure sides AB, OB, and OA of the triangle.'], {fixed:true, fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
		brd2.create('text', [0.5,12, 'Protractor to measure angle &ang;AOB of the triangle.'], {fixed:true, fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
    var shuffle = placeShuffle(brd2);
    shuffle.setLabel('Tap to Shuffle');
    shuffle.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
    shuffle.on('over', function () {this.label.setAttribute({visible:true});});
    shuffle.on('out', function () {this.label.setAttribute({visible:false});});
    var erase = placeErase(brd2);
    erase.setLabel('Tap to Erase');
    erase.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
    erase.on('over', function () {this.label.setAttribute({visible:true});});
    erase.on('out', function () {this.label.setAttribute({visible:false});});
		//Tape measure
		//var Ptm1 = brd2.create('point', [-4, -4], {name:'', fillColor:'orange', strokeColor:'black', face:'square', size:function(){return 4*brd2.canvasHeight/800}});
		//var Ptm2 = brd2.create('point', [-6, -6], {name:'', face:'square', size:function(){return 2*brd2.canvasHeight/800}});
		//var Im =brd2.create('image', ['/assets/measure.svg', [-6,-4.45],[2, 2.]], {opacity:1});
		var tape= brd2.create('tapemeasure', [[8, 5],[12, 5]], {dash:1, strokeColor:'black', point1:{fillColor:'orange', strokeColor:'black', face:'square', size:function(){return 5*brd2.canvasHeight/800}}, point2:{fillColor:'orange', strokeColor:'black', face:'square', size:function(){return 5*brd2.canvasHeight/800}}, label:{offset:[-5, 10], fontSize:function(){return 18*brd2.canvasHeight/800}, Color:'blue', cssStyle:'fontFamily:Oswald'}});
		//
		//var Pt0 =brd2.create('point', [11, 8], {name:'', face:'square', size:function(){return 3*brd2.canvasHeight/800}});
		//Triangle
		var A =brd2.create('point', [0, 0], {name:'O', size:2, face:'square', color:'black', fixed:true,label:{fontSize:function(){return 16*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald'} });
		var B =brd2.create('point', [5, 0], {name:'A', size:2, color:'black', face:'square', fixed:true,label:{fontSize:function(){return 16*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
		var C =brd2.create('point', [5, 5], {name:'B (Drag me!)', size:2, color:'black', face:'square', snapToGrid:true,label:{fontSize:function(){return 16*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
		brd2.create('polygon', [B, A, C], {visible:true, dash:1, borders:{visible:true, strokeColor:'black'}, fillColor:'yellow', fixed:true});
		//
		var lac = brd2.create('segment', [A, C],{strokeWidth:0});
		var Pt1 =brd2.create('point', [10, 8], {name:'Drag me!', strokeColor:'black', label:{fontSize:function(){return 12*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald', offset:[-10, -15]}, size:function(){return 3*brd2.canvasHeight/800}, attractors:[A, B, C], attractorDistance:0.5, snapDistance:0.5});

		var Pt0 =brd2.create('point', [function(){return Pt1.X()+2}, function(){return Pt1.Y()}], {name:'', strokeColor:'black', size:function(){return 3*brd2.canvasHeight/800}, attractors:[A, B, C], attractorDistance:0.5, snapDistance:0.5});

		var circ=brd2.create('arc', [Pt1, [function(){return Pt1.X()+2;}, function(){return Pt1.Y();}],[function(){return Pt1.X()-2;}, function(){return Pt1.Y();}]],{visible:true, strokeWidth:0.5, strokeColor:'black', dash:1, center:{visible:false}, radiuspoint:{visible:false}, anglepoint:{visible:false}});

        var Pt2 =brd2.create('glider', [11,10, circ], {face:'circle', size:3, name:'To Slide!', strokeColor:'black', label:{fontSize:function(){return 12*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}, attractors:[lac], attractorDistance:0.5, snapDistance:0.05});


		var ang= brd2.create('angle', [Pt0, Pt1, Pt2],{name:function(){return '&alpha; ='+ (JXG.Math.Geometry.rad(Pt0, Pt1, Pt2)*180/Math.PI).toFixed(1)},face:'square', radius:2, strokeColor:'black', label:{offset:[20, -10], fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}, fillColor:'orange'});
		//Protractor
		brd2.create('image', ['/assets/protractor.svg', [function(){return Pt1.X()-2}, function(){return Pt1.Y()-1}],[4, 4.]], {opacity:1});
		var txt = ['The angle is a right angle', 'The angle is an acute angle', 'The angle is an obtuse angle']
		var acute = function(){if(ang.Value()< Math.PI/2){return txt[1]} else if(ang.Value()> Math.PI/2){return txt[2]} else{return txt[0]}};
		C.on('down', function(){return i=0});
		Pt2.on('down', function(){return i=1});
		brd2.create('text', [0.5,-2, function(){return '' + acute()}], {fixed:true, fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;', visible:function(){if(i==0){return false}else{return true}}});
		//
    },
    }
export default Boxes;
